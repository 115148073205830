import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { VEHICLES_LINSTINGS } from "data/listings";
import { CarDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
var numeral = require('numeral');

export interface StayCardProps {
  className?: string;
  ratioClass?: string;
  data?: CarDataType;
  size?: "default" | "small";
}

//const DEMO
let X: CarDataType[]

VEHICLES_LINSTINGS.then((doc
) => {
  X = doc
});


const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data = X[0],
  ratioClass,
}) => {
  const {
    id,
    galleryImgs,
    listingCategory,
    transmission,
    title,
    gearshift,
    href,
    like,
    saleOff,
    isAds,
    year,
    price,
    seats,
    km,
    reviewStart,
    reviewCount,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider ratioClass={ratioClass} galleryImgs={galleryImgs} />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {year} · {numeral(km)
              .format('0,0')} km
          </span>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >

              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">

            <span className="">Transmisión: {transmission.charAt(0).toUpperCase() + transmission.slice(1)} </span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            A Consultar
            {/* {numeral(price)
              .format('$0,0.00')} */}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              </span>
            )}
          </span>
          {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <Link to={href + "/" + id}>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default StayCard;

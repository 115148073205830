
import StartRating from "components/StartRating/StartRating";
import React, { useState, useEffect, FC } from "react";
import Button from "shared/Button/Button";
import NcImage from "shared/NcImage/NcImage";
import { CarDataType } from "data/types";
import { getDetail } from "data/listings";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {


  // USE STATE PHOTOS
  const [PHOTOS, setphotosvehicle] = useState<string[]>([]);
  const [VehicleActual, setdatavehicle] = useState<CarDataType | undefined>(undefined);

  // CONSULT FIREBASE
  function ObtainPhotos(queryString: string) {
    getDetail(queryString).then((g) => {
      g.forEach(data => {
        setphotosvehicle(data.galleryImgs)
        setdatavehicle(data);
      });
    });
  };

  useEffect(() => {
    let querystring = window.location.pathname.split("/", 3)[2];
    ObtainPhotos(querystring);
  }, []);

  console.log("DATA",VehicleActual)
  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Obten tu Vehículo 🎉
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">{VehicleActual?.title}</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={VehicleActual?.featuredImage} />
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Detalles</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Sucursal</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                El Rosal
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Dirección</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
              Calle Carabobo con calle Sojo, El Rosal Concesionario Kocars, Caracas 1060, Miranda
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Horario</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                9:00 a.m - 5:00 p.m
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Contacto</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
              (+58) 424 131-83-35
              </span>
            </div>
          </div>
        </div>
        <div>
          <Button className="disabled:bg-opacity-70 bg-primary-500 text-neutral-50 "><a href={"https://wa.me/584241318335?text=Hola%20me%20interesa%20este%20vehiculo:%20" + VehicleActual?.title}>Contactar por Whatsapp</a></Button>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;

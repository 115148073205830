import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Marcas Populares",
    menus: [
      { href: "#", label: "Chevrolet" },
      { href: "#", label: "Toyota" },
      { href: "#", label: "Honda" },
      { href: "#", label: "Ford" },
      { href: "#", label: "Mitsubishi" },
      { href: "#", label: "Renault" },
    ],
  },
  {
    id: "1",
    title: "Vender Vehículos",
    menus: [
      { href: "#", label: "Solicitar Citas" },
      { href: "#", label: "Como funciona" },
      { href: "#", label: "Habla con Ventas" },
      { href: "#", label: "Seguridad" },
      { href: "#", label: "Consignación" },
      { href: "#", label: "Verificación" },
    ],
  },
  {
    id: "2",
    title: "Servicios",
    menus: [
      { href: "#", label: "Revisión" },
      { href: "#", label: "Mecánica" },
      { href: "#", label: "Balanceo" },
      { href: "#", label: "Lavado" },
      { href: "#", label: "Pulitura" },
      { href: "#", label: "Tapicería" },
    ],
  },
  {
    id: "4",
    title: "Miembros",
    menus: [
      { href: "#", label: "Acceso" },
      { href: "#", label: "Status" },
      { href: "#", label: "Tus Vehículos" },
      { href: "#", label: "Agendar Citas" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-4 lg:py-4 border-t border-neutral-200 dark:border-neutral-700">
    <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
      <div className="col-span-12 md:col-span-12 flex justify-between ">
        <Logo />
        <SocialsList1 className="flex items-center " />
      </div>
    </div>
</div>
  );
};

export default Footer;

import React, { FC, Dispatch, SetStateAction } from "react";
import Textarea from "shared/Textarea/Textarea";
import { CarWorkShopForm } from "data/types";

export interface PageAddListing6Props {
  setdescription?: Dispatch<SetStateAction<string>>;
  CarWorkShopForm?: CarWorkShopForm,
}

const PageAddListing6: FC<PageAddListing6Props> = ({
  setdescription = () => { },
  CarWorkShopForm: CarWorkShopForm = {},
}) => {
  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">
          Motivo de Solicitud
        </h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          -
        </span>
      </div>

      <Textarea placeholder="Detalles del vehículo para la solicitud." rows={14} onChange={(e) => { setdescription(e.target.value) }} value={CarWorkShopForm?.description} />
    </>
  );
};

export default PageAddListing6;

import __stayListing from "./jsons/__stayListing.json";
//import __carsListing from "./jsons/__carsListing.json";
import __experiencesListing from "./jsons/__experiencesListing.json";
import {
  DEMO_STAY_CATEGORIES,
  DEMO_EXPERIENCES_CATEGORIES,
} from "./taxonomies";
import { CarDataType, ExperiencesDataType, StayDataType } from "./types";
import { DEMO_AUTHORS } from "./authors";
/*FIREBASE*/
import { db } from "database/firebase";
import { collection, getDocs, doc } from 'firebase/firestore/lite';
/*DATABASE*/


/*
const carsImgs = [
  car1,
  car2,
  car3,
  car4,
  car5,
  car6,
  car7,
  car8,
  car9,
  car10,
  car11,
  car12,
  car13,
  car14,
  car15,
  car16,
];
*/
const DEMO_STAY_LISTINGS = __stayListing.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    saleOff: !index ? "-20% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_EXPERIENCES_LISTINGS = __experiencesListing.map(
  (post, index): ExperiencesDataType => {
    //  ##########  GET CATEGORY BY CAT ID ######## //
    const category = DEMO_EXPERIENCES_CATEGORIES.filter(
      (taxonomy) => taxonomy.id === post.listingCategoryId
    )[0];

    return {
      ...post,
      saleOff: !index ? "-20% today" : post.saleOff,
      isAds: !index ? true : post.isAds,
      author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
      listingCategory: category,
    };
  }
);

let x: CarDataType[];

// Get a list of cities from your database
async function getVehicles(): Promise<CarDataType[]> {
  const snapshot = await getDocs(collection(doc(collection(db, "dealership_vehicles"), "active"), "vehicles"));
  x = []
  snapshot.forEach(doc => {
    var vehicle = doc.data();
    //  ##########  GET CATEGORY BY CAT ID ######## //
    const category = DEMO_EXPERIENCES_CATEGORIES.filter(
      (taxonomy) => taxonomy.id === 1
    )[0];

    x.push({
      id: doc.id,
      author: DEMO_AUTHORS.filter((user) => user.id === 1)[0],
      branch_name: vehicle.branch_name,
      date: "May 20, 2021",
      href: "/detalle-vehiculo",
      listingCategory: category,
      title: vehicle.brand_name + " " + vehicle.model_name,
      featuredImage: vehicle.thumbnail,
      galleryImgs: vehicle.photos,
      commentCount: 19,
      viewCount: 527,
      like: true,
      feature: vehicle.feature,
      transmission: vehicle.transmission,
      body: vehicle.body,
      logo: vehicle.logo,
      year: vehicle.year,
      description: vehicle.description,
      engine: vehicle.engine,
      engine_type: vehicle.engine_type,
      hp: vehicle.hp,
      color: vehicle.color,
      reviewStart: 5.0,
      reviewCount: 280,
      price: vehicle.price + "$",
      gearshift: vehicle.condition,
      km: vehicle.km,
      seats: 4,
      saleOff: "",
      isAds: null,
      map: { "lat": 59.955413, "lng": 30.337844 }
    })
  });

  return x;

};

const VEHICLES_LINSTINGS: Promise<CarDataType[]> = getVehicles();

let s: CarDataType[];
// Get a list of cities from your database
async function getDetail(id: string): Promise<CarDataType[]> {
  const snapshot = await getDocs(collection(doc(collection(db, "dealership_vehicles"), "active"), "vehicles"));
  s = [];

  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_EXPERIENCES_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === 1
  )[0];
  if (snapshot.empty) {

    s.push({
      id: 1,
      author: DEMO_AUTHORS.filter((user) => user.id === 1)[0],
      branch_name: 'El Rosal',
      date: "May 20, 2021",
      href: "/detalle-vehiculo",
      listingCategory: category,
      title: "BMW 3 Series Sedan",
      featuredImage: "",
      galleryImgs: [
        "https://images.pexels.com/photos/381292/pexels-photo-381292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/2526128/pexels-photo-2526128.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/2827753/pexels-photo-2827753.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/1637859/pexels-photo-1637859.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/257851/pexels-photo-257851.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/189454/pexels-photo-189454.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/193995/pexels-photo-193995.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/575386/pexels-photo-575386.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
      ],
      commentCount: 19,
      feature: [],
      viewCount: 527,
      like: true,
      transmission: "Manual",
      body: "Copacto",
      logo: "",
      year: 2021,
      description: "",
      engine: "",
      engine_type: "",
      hp: 0,
      color: "",
      reviewStart: 5.0,
      reviewCount: 280,
      price: "19000" + "$",
      gearshift: "New",
      km: "0",
      seats: 4,
      saleOff: "",
      isAds: null,
      map: { "lat": 59.955413, "lng": 30.337844 }
    })

  } else {
    snapshot.forEach(doc => {
      var vehicle = doc.data();
      if (id == doc.id) {
        s.push({
          id: doc.id,
          author: DEMO_AUTHORS.filter((user) => user.id === 1)[0],
          branch_name: vehicle.branch_name,
          date: "May 20, 2021",
          href: "/detalle-vehiculo",
          listingCategory: category,
          title: vehicle.brand_name + " " + vehicle.model_name,
          featuredImage: vehicle.thumbnail,
          galleryImgs: vehicle.photos,
          feature: vehicle.feature,
          commentCount: 19,
          viewCount: 527,
          like: true,
          transmission: vehicle.transmission,
          body: vehicle.body,
          logo: vehicle.logo,
          year: vehicle.year,
          description: vehicle.description,
          engine: vehicle.engine,
          engine_type: vehicle.engine_type,
          hp: vehicle.hp,
          color: vehicle.color,
          reviewStart: 5.0,
          reviewCount: 280,
          price: vehicle.price + "$",
          gearshift: vehicle.condition,
          km: vehicle.km,
          seats: 4,
          saleOff: "",
          isAds: null,
          map: { "lat": 59.955413, "lng": 30.337844 }
        })
      }
    });
  }

  return s;
};

export { DEMO_STAY_LISTINGS, DEMO_EXPERIENCES_LISTINGS, VEHICLES_LINSTINGS, getDetail };

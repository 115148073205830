import React, { useState, useEffect, FC } from "react";
import StayCard from "components/StayCard/StayCard";
import { VEHICLES_LINSTINGS } from "data/listings";
import { CarDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
//import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const [DATA, setdata] = useState<CarDataType[] | undefined>(undefined)

  const Changer = async () => {
    const X: CarDataType[] = await VEHICLES_LINSTINGS;
    console.log("CHARGER X :", X);
    const DEMO_DATA: CarDataType[] = X.filter((_, i) => i < 8)
    console.log("CHARGER DEMO_DATA :", DEMO_DATA);
    setdata(DEMO_DATA)
  }

  useEffect(() => {
    Changer()
  }, []);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 />
      <div className="mb-8 lg:mb-11">
      </div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {DATA === undefined ? '' : DATA.map((stay) => 
          <StayCard key={stay.id} data={stay} />
        )}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;

import SectionHero from "components/SectionHero/SectionHero";
import React from "react";
import { Helmet } from "react-helmet";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Kocars - Venta de Vehiculos</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      <div className="container relative space-y-20 mb-24">
        <SectionHero className="pt-10 lg:pt-28"/>
        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>
        {/* SECTION */}
        <SectionHowItWork />
      </div>
    </div >
  );
}

export default PageHome;

        //{ /*SECTION 1 */}
/*
<SectionSliderNewCategories categories={DEMO_CATS} />
 
*/
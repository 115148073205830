import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import { CarouselType } from "data/types";

export interface SectionCarouselProps {
  className?: string;
  CarouselType?: CarouselType[];
}

const SectionCarousel: FC<SectionCarouselProps> = ({ className = "" , CarouselType=[]}) => {
  return (
    <div
      className={`nc-SectionCarousel  ${className}`}
      data-nc-id="SectionCarousel"
    >
      <Heading isCenter desc="">
      ¿Por qué escoger Kocars?
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {CarouselType?.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="mb-8 max-w-[200px] mx-auto"
              src={item.img}
            />
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionCarousel;

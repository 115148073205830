import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/CSR1.png";
import HIW2img from "images/CSR2.png";
import HIW3img from "images/CSR3.png";
import VectorImg from "images/VectorHIW.svg";
import { CarouselType } from "data/types";

export interface SectionHowItWorkProps {
  className?: string;
}

const DEMO_DATA: CarouselType[] = [{
  id: 1,
  img: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FImages%2FCSR1.png?alt=media&token=f3a68238-faa8-40ad-8ce2-c5faf3ce1599",
  title: "Certificamos todos nuestros autos",
  desc: "Todos nuestros vehículos son certificados para asegurar que estén en óptimas condiciones.",
},
{
  id: 2,
  img: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FImages%2FCSR2.png?alt=media&token=a15a2ce4-7eb1-4005-9a14-652441e160df",
  title: "Servicio Post-Venta",
  desc: "Contamos con instalaciones de un taller en El Rosal que le permite seguir cuidando de su inversión a través de un equipo con años de experiencia. ",
},
{
  id: 3,
  img: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FImages%2FCSR3.png?alt=media&token=ffc60bd1-4dc1-4238-9457-650892577ffb",
  title: "Confianza",
  desc: "Conocemos la procedencia de su vehículo y le acompañamos durante todo el proceso de compra, venta o reparación.",
},
]

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <br/>
      <Heading isCenter desc="">
      ¿Por qué escoger Kocars?
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {DEMO_DATA.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="mb-8 max-w-[200px] mx-auto"
              src={item.img}
            />
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;

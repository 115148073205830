import React, { FC, Dispatch, SetStateAction } from "react";
import Textarea from "shared/Textarea/Textarea";
import { CarForm } from "data/types";

export interface PageAddListing6Props {
  setdescription?: Dispatch<SetStateAction<string>>;
  CarForm?: CarForm,
}

const PageAddListing6: FC<PageAddListing6Props> = ({
  setdescription = () => { },
  CarForm: CarForm = {},
}) => {
  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">
          Descripción del Vehículo
        </h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          -
        </span>
      </div>

      <Textarea placeholder="Color, Cant. de Dueños, Cauchos, Aire Acondicionado, Tapicería, Batería, Historial de Incidentes Conocidos, etc. " rows={14} onChange={(e) => { setdescription(e.target.value) }} value={CarForm?.description} />
    </>
  );
};

export default PageAddListing6;

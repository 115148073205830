import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse relative ${className}`}
      data-nc-id="SectionHero"
    >
      {/* <div className="flex flex-col lg:flex-row"> */}
      <div className="flex flex-col lg:flex-row lg:items-center">
        {/* <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pt-11 pb-14 lg:pb-60 xl:pr-14 lg:mr-10 xl:mr-0"> */}
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            Bienvenidos a Grupo Kocars
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
          Grupo automotriz especializado en la <b>compra y venta</b> de vehículos seminuevos, asesoría, seguros y trámites de documentos automotrices y servicios en nuestra <b>área de taller</b>.
</span>
<span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
Tenemos más de <b>30 años</b> de experiencia en el mercado automotriz, personal de alto reconocimiento, instalaciones de primera y una ubicación inmejorable.</span>
<b className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
Seguridad para usted y su vehículo.
          </b>
          
          <ButtonPrimary ><a href="https://kocars-client.firebaseapp.com">Área de Miembros</a></ButtonPrimary>
        </div>
        <div className="flex-grow pb-24 lg:pb-32">
          <img className="w-full group relative bg-white rounded-2xl overflow-hidden" src="https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FHome%2Festablishment.jpeg?alt=media&token=57b1249d-7457-475b-bb67-8280ccd18765" alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;

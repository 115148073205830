// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore/lite';
// Follow this pattern to import other Firebase services


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDISydVWF4-phSiRqeMKkvjxiXFoTIIroA",
    authDomain: "kocars-desarrollo.firebaseapp.com",
    projectId: "kocars-desarrollo",
    storageBucket: "kocars-desarrollo.appspot.com",
    messagingSenderId: "1099500357491",
    appId: "1:1099500357491:web:5f611f9cf706aa2bb02706",
    measurementId: "G-BD0H704EBQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app, 'gs://kocars-desarrollo.appspot.com');

export { db, storage };
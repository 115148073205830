import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";

const PageInfo: React.FC = () => (
  <div className="nc-Page404">
    <Helmet>
      <title>Kocars</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5 h-screen flex justify-center items-center">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2 ">
        {/* <NcImage src={I404Png} /> */}
        <span className="block text-sm text-neutral-800 md:text-3xl dark:text-neutral-200 tracking-wider font-medium ">
        ¡SU SOLICITUD FUE ENVIADA CON ÉXITO!{" "}
        </span>
        <div className="pt-8">
          <ButtonPrimary href="/">Inicio</ButtonPrimary>
        </div>
      </header>
    </div>
  </div>
);

export default PageInfo;

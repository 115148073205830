import CarCard from "components/CarCard/CarCard";
import { CarAlianzaForm } from "data/types";
import React, { Dispatch, FC, SetStateAction } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface PageAddListing10Props {
  setIndex?: Dispatch<SetStateAction<number>>;
  CarWorkShopForm?: CarAlianzaForm;
}

const PageAddListing10: FC<PageAddListing10Props> = ({
  setIndex = () => {},
  CarWorkShopForm,
}) => {
  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">
          Resumen del servicio solicitado
        </h2>
      </div>
      <div className="flex items-center border-neutral-200 dark:border-neutral-700 text-sm space-x-2">
        <span className="text-base font-semibold">Dueño:</span>
        <span className="">{CarWorkShopForm?.name}</span>
      </div>

      <div className="flex items-center border-neutral-200 dark:border-neutral-700 text-sm space-x-2">
        <span className="text-base font-semibold">Teléfono:</span>
        <span className="">{CarWorkShopForm?.phone}</span>
      </div>
      <div className="flex items-center border-neutral-200 dark:border-neutral-700 text-sm space-x-2">
        <span className="text-base font-semibold">Auto:</span>
        <span className="">
          {CarWorkShopForm?.brand + " " + CarWorkShopForm?.model}
        </span>
      </div>
      <div className="flex items-center border-neutral-200 dark:border-neutral-700 text-sm space-x-2">
        <span className="text-base font-semibold">Km:</span>
        <span className="">{CarWorkShopForm?.km}</span>
      </div>
      <div className="flex items-center border-neutral-200 dark:border-neutral-700 text-sm space-x-2">
        <span className="text-base font-semibold">Placa:</span>
        <span className="">{CarWorkShopForm?.tag}</span>
      </div>
      <div className="flex items-center border-neutral-200 dark:border-neutral-700 text-sm space-x-2">
        <span className="text-base font-semibold">Procendencia:</span>
        <span className="">{CarWorkShopForm?.origin}</span>
      </div>
      <div className="flex items-center border-neutral-200 dark:border-neutral-700 text-sm space-x-2">
        <span className="text-base font-semibold">Servicio:</span>
        <span className="">{CarWorkShopForm?.service}</span>
      </div>
    </>
  );
};

export default PageAddListing10;

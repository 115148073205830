import React, { FC, Dispatch, SetStateAction, ChangeEvent } from "react";
import { CarForm } from "data/types";

export interface PageAddListing7Props {
  CarForm?: CarForm;
  setphoto?: Dispatch<SetStateAction<string>>;
  setb64?: Dispatch<SetStateAction<string>>;
}
async function getBase64(file: FileList | undefined): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        if (reader.result !== null) {
          let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '');
          if ((encoded?.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
        };
      };
      reader.onerror = error => reject(error);
    }
  });
}

const PageAddListing7: FC<PageAddListing7Props> = ({
  CarForm: CarForm = {},
  setphoto = () => { },
  setb64 = () => { },
}) => {
  var FilesList: FileList | null
  function onChange(event: ChangeEvent<HTMLInputElement>) {

    if (event.target.files?.[0].name !== null) {
      FilesList = event.target.files;
      if (FilesList != null) {
        setphoto(URL.createObjectURL(FilesList[0]));
        getBase64(FilesList).then((X) => {
          setb64(X);
        });
      }
    }
  }

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">Anexa una Foto del Vehículo </h2>
      </div>

      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* FORM */}
      <div className="space-y-8">
        <div className="mt-5 ">
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <span hidden={CarForm?.photo === "" ? false : true}>
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
              <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                <label
                  htmlFor="file-upload-2"
                  className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                >
                  <span hidden={CarForm?.photo === "" ? false : true}>Selecciona o arrastra una foto del vehículo</span>
                  <input
                    id="file-upload-2"
                    name="file-upload-2"
                    type="file"
                    accept='image/png, image/jpeg'
                    className="sr-only"
                    onChange={(e) => { onChange(e) }}
                  />
                  <img src={CarForm?.photo} />
                </label>
              </div>
              <p className="text-xs text-neutral-500 dark:text-neutral-400">
                -
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageAddListing7;

import SectionHero from "components/SectionHero/SectionHero";
import React from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";

function PageVitrina() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-20 mb-24">
        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>
      </div>
    </div >
  );
}

export default PageVitrina;
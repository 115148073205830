import {NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Inicio",
  },
  {
    id: ncNanoId(),
    href: "/vitrina-de-vehiculos",
    name: "Vitrina de Vehículos",
  },
  {
    id: ncNanoId(),
    href: "/publica-tu-vehiculo",
    name: "Publica tu Vehículo",
  },
  {
    id: ncNanoId(),
    href: "/servicios-taller",
    name: "Servicios y Taller",
  },
  {
    id: ncNanoId(),
    href: "/contacto",
    name: "Contacto",
  },
];

import React, { FC, Dispatch, SetStateAction } from "react";
import Textarea from "shared/Textarea/Textarea";
import { CarAlianzaForm, NcDropDownItem } from "data/types";
import NcDropDown from "shared/NcDropDown/NcDropDown";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import Input from "shared/Input/Input";

export interface PageAddListing6Props {
  setService?: Dispatch<SetStateAction<string>>;
  CarWorkShopForm?: CarAlianzaForm;
}

const data: NcDropDownItem[] = [
  {
    id: "1",
    name: "Limpieza inyectores ($80)",
    description: [
      "Limpieza cuerpo de aceleración",
      "Complemento en niveles de fluidos",
      "Calibrado de bujías (de ser necesario)",
      "Lavado carrocería / aspirado",
    ],
    price: "",
  },
  {
    id: "2",
    name: "Cambio de aceite (5 Litros) ($70)",
    description: [
      "Diagnóstico computarizado (escáner)",
      "Complemento de niveles de fluidos",
      "Lavado carrocería / aspirado",
    ],
    price: "",
  },
  {
    id: "3",
    name: "Cambio de aceite (7 Litros) ($120)",
    description: [
      "Reemplazo filtro de aire y de motor",
      "Diagnóstico computarizado completo (escáner + tacto y visual)",
      "Complemento de niveles de fluidos",
      "Lavado carrocería / aspirado",
    ],
    price: "",
  },
  {
    id: "4",
    name: "Cambio de aceite (8 Litros) ($160)",
    description: [
      "Reemplazo filtro de aire y de motor",
      "Reemplazo filtro anti-polen (cabina)",
      "Limpieza de frenos",
      "Diagnóstico computarizado completo (escáner + tacto y visual)",
      "Lavado carrocería / aspirado",
    ],
    price: "",
  },
  {
    id: "5",
    name: "Pulitura carrocería ($80)",
    description: ["Trabajo con material calidad 3M"],
    price: "",
  },
];

const PageAddListing6: FC<PageAddListing6Props> = ({
  setService = () => {},
  CarWorkShopForm: CarWorkShopForm = {},
}) => {
  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">Seleccionar servicio</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          -
        </span>
      </div>

      {/* <Textarea placeholder="Motivo de solicitud de servicios." rows={14} onChange={(e) => { setdescription(e.target.value) }} value={CarWorkShopForm?.description} /> */}

      <FormItem label="Servicio que desea reservar">
        <Input
          placeholder="Descripción del servicio"
          onChange={(e) => {
            setService(e.target.value);
          }}
        />
      </FormItem>

      {/* <FormItem label="Selecciona un servicio">
        <Select
          onChange={(e) => {
            setService(e.target.value);
          }}
        >
          <option value="Limpieza inyectores ($80)">
            Limpieza inyectores ($80)
          </option>
          <option value="Cambio de aceite (5 Litros) ($70)">
            Cambio de aceite (5 Litros) ($70)
          </option>
          <option value="Cambio de aceite (7 Litros) ($120)">
            Cambio de aceite (7 Litros) ($120)
          </option>
          <option value="Cambio de aceite (8 Litros) ($160)t">
            Cambio de aceite (8 Litros) ($160)
          </option>
          <option value="Pulitura carrocería ($80)">
            Pulitura carrocería ($80)
          </option>
        </Select>
      </FormItem> */}

      {/* <div className="flex flex-col items-center border-neutral-200 dark:border-neutral-700 text-sm space-x-2">
        <span className="text-base font-semibold">
          Descripción de Servicio:
        </span>
        {CarWorkShopForm?.service?.description != undefined &&
          CarWorkShopForm?.service?.description.map(
            (doc: string, index: number) => (
              <span className="mt-4" key={index}>
                {doc}
              </span>
            )
          )}
      </div> */}
    </>
  );
};

export default PageAddListing6;

import React, { Dispatch, useState, FC, SetStateAction } from "react";
import Input from "shared/Input/Input";
// import NcDropDown from "shared/NcDropDown/NcDropDown";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import { CarAlianzaForm, NcDropDownItem } from "data/types";

export interface PageAddListing1Props {
  CarWorkShopForm?: CarAlianzaForm;
  setFullName?: Dispatch<SetStateAction<string>>;
  setphone?: Dispatch<SetStateAction<string>>;
  setmark?: Dispatch<SetStateAction<string>>;
  setmodel?: Dispatch<SetStateAction<string>>;
  setyear?: Dispatch<SetStateAction<string>>;
  setkm?: Dispatch<SetStateAction<string>>;
  settag?: Dispatch<SetStateAction<string>>;
  setOrigin?: Dispatch<SetStateAction<string>>;
}

const PageAddListing1: FC<PageAddListing1Props> = ({
  CarWorkShopForm: CarWorkShopForm = {},
  setFullName = () => {},
  setphone = () => {},
  setmark = () => {},
  setmodel = () => {},
  setyear = () => {},
  setkm = () => {},
  settag = () => {},
  setOrigin = () => {},
}) => {
  return (
    <>
      <h2 className="text-2xl font-semibold">Solicitud de registro</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* FORM */}
      <div className="space-y-8">
        {/* ITEM */}
        <div>
          <tr className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            <td>
              <FormItem label="Nombre Completo">
                <Input
                  placeholder="Nombre Completo"
                  value={CarWorkShopForm?.name}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                />
              </FormItem>
            </td>
            <td>
              <FormItem label="Teléfono">
                <Input
                  maxLength={13}
                  placeholder="Teléfono"
                  value={CarWorkShopForm?.phone}
                  onChange={(e) => {
                    setphone(e.target.value);
                  }}
                />
              </FormItem>
            </td>
          </tr>
        </div>
        <div>
          <tr className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            <td>
              <FormItem label="Marca">
                <Input
                  placeholder="Marca"
                  value={CarWorkShopForm?.brand}
                  onChange={(e) => {
                    setmark(e.target.value);
                  }}
                />
              </FormItem>
            </td>
            <td>
              <FormItem label="Modelo">
                <Input
                  placeholder="Modelo"
                  value={CarWorkShopForm?.model}
                  onChange={(e) => {
                    setmodel(e.target.value);
                  }}
                />
              </FormItem>
            </td>
          </tr>
        </div>
        <div>
          <tr className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            <td>
              <FormItem label="Año">
                <Input
                  type="number"
                  placeholder="Año"
                  value={CarWorkShopForm?.year}
                  onChange={(e) => {
                    setyear(e.target.value);
                  }}
                />
              </FormItem>
            </td>
            <td>
              <FormItem label="Kilometraje">
                <Input
                  type="number"
                  placeholder="Kilometraje"
                  value={CarWorkShopForm?.km}
                  onChange={(e) => {
                    setkm(e.target.value);
                  }}
                />
              </FormItem>
            </td>
          </tr>
          <FormItem label="Placa">
            <Input
              maxLength={8}
              placeholder="Placa"
              value={CarWorkShopForm?.tag}
              onChange={(e) => {
                settag(e.target.value);
              }}
            />
          </FormItem>

          <FormItem label="Procedencia">
            <Select
              onChange={(e) => {
                setOrigin(e.target.value);
              }}
            >
              <option value="Centro Portugués">Centro Portugués</option>
              <option value="AutoMarket">AutoMarket</option>
              <option value="Ecolanding">Ecolanding</option>
            </Select>
          </FormItem>
        </div>
      </div>
    </>
  );
};

export default PageAddListing1;

import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { CarDataType, CarForm } from "data/types";
import PageAddListing1 from "./PageAddListing1";
import PageAddListing6 from "./PageAddListing6";
import PageAddListing7 from "./PageAddListing7";
import PageAddListing10 from "./PageAddListing10";
import __experiencesListing from "data/jsons/__experiencesListing.json";
import {
  DEMO_EXPERIENCES_CATEGORIES,
} from "data/taxonomies";
import { DEMO_AUTHORS } from "data/authors";
/*FIREBASE*/
import { db, storage } from "database/firebase";
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore/lite';
import { ref, uploadString, UploadMetadata, getDownloadURL } from 'firebase/storage';
/*DATABASE*/

export interface CommonLayoutProps { }

const CommonLayout: FC<CommonLayoutProps> = () => {
  // State Form
  const [index, setIndex] = useState<number>(1);
  const [name, setFullName] = useState<string>('');
  const [phone, setphone] = useState<string>("");
  const [brand, setmark] = useState<string>("");
  const [model, setmodel] = useState<string>("");
  const [year, setyear] = useState<string>("");
  const [km, setkm] = useState<string>("");
  const [statevehicle, setstatevehicle] = useState<string>("");
  const [description, setdescription] = useState<string>("");
  const [photo, setphoto] = useState<string>("");
  const [b64, setb64] = useState<string>("");

  function changeIndex() {
    switch (index) {
      case 1:
        if (name && phone && brand && model && year && km) {
          let value = index;
          setIndex(value + 1)
        }
        break;
      case 2:
        if (description) {
          let value = index;
          setIndex(value + 1)
        }
        break;
      case 3:
        if (photo && b64) {
          let value = index;
          setIndex(value + 1)
        }
        break;
    
      default:
        break;
    }
  }
  function changeIndexRest() {
    let value = index;
    setIndex(value - 1)
  }

  function InsertCar(car: CarForm) {

    addDoc(collection(db, 'dealership_appointments'), {
      brand: car.brand,
      year: car.year,
      description: car.description,
      km: car.km,
      model: car.model,
      name: car.name,
      phone: car.phone,
      picture: "#",
    }).then((x) => {
      const a = ref(storage, 'appointments/' + x.id+'/'+x.id)
      const metadata: UploadMetadata = {
        contentType: 'image/jpeg',
      }
      const b = uploadString(a, car.b64, 'base64', metadata)
      b.then((c) => {
        getDownloadURL(a).then((f) => {
          updateDoc(doc(db, 'dealership_appointments', x.id), { picture: f})
        })
      });
    });
  }

  const X: CarForm = {
    name: name,
    phone: phone,
    brand: brand,
    model: model,
    year: year,
    km: km,
    description: description,
    photo: photo,
    b64: b64,
  }

  const category = DEMO_EXPERIENCES_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === 1
  )[0];
  const CarSelect: CarDataType = {
    id: 1,
    author: DEMO_AUTHORS.filter((user) => user.id === 1)[0],
    branch_name: 'El Rosal',
    date: Date.UTC.toLocaleString(),
    href: "",
    listingCategory: category,
    title: brand + " " + model,
    featuredImage: photo,
    galleryImgs: [],
    commentCount: 0,
    viewCount: 0,
    like: true,
    transmission: "Manual",
    body: "Compacto",
    logo: "",
    year: Number(year),
    description: description,
    engine: "Motor",
    engine_type: "Gas",
    hp: 20,
    color: '',
    reviewStart: 5.0,
    reviewCount: 280,
    price: "",
    gearshift: statevehicle,
    feature: [],
    km: km,
    seats: 4,
    saleOff: "",
    isAds: null,
    map: { "lat": 59.955413, "lng": 30.337844 }
  }

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 4
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">
          {index === 1 ? <PageAddListing1 CarForm={X} setFullName={setFullName} setphone={setphone} setmark={setmark} setmodel={setmodel} setyear={setyear} setkm={setkm} setstatevehicle={setstatevehicle} /> :
            index === 2 ? <PageAddListing6 CarForm={X} setdescription={setdescription} /> :
              index === 3 ? <PageAddListing7 CarForm={X} setphoto={setphoto} setb64={setb64} /> :
                <PageAddListing10 CarSelect={CarSelect} setIndex={setIndex} />}
        </div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          {index > 1 && <ButtonSecondary onClick={() => changeIndexRest()}>Atrás</ButtonSecondary>}
          {index < 4 && <ButtonPrimary onClick={() => changeIndex()}>
            {"Continuar"}
          </ButtonPrimary>}
          {index === 4 && <ButtonPrimary href="/info" onClick={() => InsertCar(X)}>
            {"Finalizar"}
          </ButtonPrimary >}
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;

import CarCard from "components/CarCard/CarCard";
import { CarDataType } from "data/types";
import React, { Dispatch, FC, SetStateAction } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface PageAddListing10Props {
  setIndex?: Dispatch<SetStateAction<number>>;
  CarSelect?: CarDataType;
}

const PageAddListing10: FC<PageAddListing10Props> = ({
  setIndex = () => { },
  CarSelect,
}) => {
  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">Resumen del Vehículo</h2>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* FORM */}
      <div>
        <h3 className="text-lg font-semibold">Este es tu vehículo a publicar.</h3>
        <div className="max-w-xs">
          <CarCard key={CarSelect?.id} data={CarSelect} />
        </div>
        {/* <div className="flex items-center space-x-5 mt-8">
          <ButtonSecondary onClick={() => setIndex(1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            <span className="ml-3">Editar</span>
          </ButtonSecondary>
        </div> */}
      </div>
      {/*  */}
    </>

  );
};

export default PageAddListing10;

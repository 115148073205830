import React, { Dispatch, useState, FC, SetStateAction } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { CarForm } from "data/types";


export interface PageAddListing1Props {
  CarForm?: CarForm;
  setFullName?: Dispatch<SetStateAction<string>>;
  setphone?: Dispatch<SetStateAction<string>>;
  setmark?: Dispatch<SetStateAction<string>>,
  setmodel?: Dispatch<SetStateAction<string>>,
  setyear?: Dispatch<SetStateAction<string>>,
  setkm?: Dispatch<SetStateAction<string>>,
  setstatevehicle?: Dispatch<SetStateAction<string>>,
}


const PageAddListing1: FC<PageAddListing1Props> = ({
  CarForm: CarForm = {},
  setFullName = () => { },
  setphone = () => { },
  setmark = () => { },
  setmodel = () => { },
  setyear = () => { },
  setkm = () => { },
  setstatevehicle = () => { },
}
) => {
  return (
    <>
      <h2 className="text-2xl font-semibold">¿Qué tipo de vehiculo quieres publicar?</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* FORM */}
      <div className="space-y-8">
        {/* ITEM */}
        <div>
          <tr className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            <td>
              <FormItem
                label="Nombre completo"
              >
                <Input placeholder="Nombre completo" value={CarForm?.name} onChange={(e) => { setFullName(e.target.value) }} />
              </FormItem>
            </td>
            <td>
              <FormItem
                label="Teléfono"
              >
                <Input placeholder="Teléfono" value={CarForm?.phone} onChange={(e) => { setphone(e.target.value) }} />
              </FormItem>
            </td>
          </tr>
        </div>
        <div>
          <tr className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            <td>
              <FormItem
                label="Marca"
              >
                <Input placeholder="Marca" value={CarForm?.brand} onChange={(e) => { setmark(e.target.value) }} />
              </FormItem>
            </td>
            <td>
              <FormItem
                label="Modelo"
              >
                <Input placeholder="Modelo" value={CarForm?.model} onChange={(e) => { setmodel(e.target.value) }} />
              </FormItem>

            </td>
          </tr>
        </div>
        <div>
          <tr className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            <td>
              <FormItem
                label="Año"
              >
                <Input type="number" placeholder="Año" value={CarForm?.year} onChange={(e) => { setyear(e.target.value) }} />
              </FormItem>
            </td>
            <td>
              <FormItem
                label="Kilometraje"
              >
                <Input type="Kilometraje" placeholder="0" value={CarForm?.km} onChange={(e) => { setkm(e.target.value) }} />
              </FormItem>
            </td>
          </tr>
        </div>
      </div>
    </>
  );
};

export default PageAddListing1;

import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import {CarWorkShopForm } from "data/types";
import WorkshopListing1 from "./WorkshopListing1";
import WorkshopListing2 from "./WorkshopListing2";
import WorkshopListing3 from "./WorkshopListing3";
import __experiencesListing from "data/jsons/__experiencesListing.json";
/*FIREBASE*/
import { db } from "database/firebase";
import { collection, addDoc} from 'firebase/firestore/lite';
import { Helmet } from "react-helmet";
/*DATABASE*/

export interface WorkshopServicesProps {
  className?: string;
}

const WorkshopServicesPage: FC<WorkshopServicesProps> = ({ className = "px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32" }) => {
  // State Form
  const [index, setIndex] = useState<number>(1);
  const [name, setFullName] = useState<string>('');
  const [phone, setphone] = useState<string>("");
  const [brand, setmark] = useState<string>("");
  const [model, setmodel] = useState<string>("");
  const [year, setyear] = useState<string>("");
  const [km, setkm] = useState<string>("");
  const [description, setdescription] = useState<string>("");
  const [tag, settag] = useState<string>("");

  function changeIndex() {
    switch (index) {
      case 1:
        if (name && phone && brand && model && year && km && tag) {
          let value = index;
          setIndex(value + 1)
        }
        break;
      case 2:
        if (description) {
          let value = index;
          setIndex(value + 1)
        }
        break;

      default:
        break;
    }
  }
  function changeIndexRest() {
    let value = index;
    setIndex(value - 1)
  }

  function InsertCar(car: CarWorkShopForm) {

    addDoc(collection(db, 'workshop_appointments'), {
      brand: car.brand,
      year: car.year,
      description: car.description,
      km: car.km,
      model: car.model,
      name: car.name,
      phone: car.phone,
      tag: car.tag,
    }).then((x) => {
     console.log("Document ID:", x.id)
    });
  }

  const X: CarWorkShopForm = {
    name: name,
    phone: phone,
    brand: brand,
    model: model,
    year: year,
    km: km,
    description: description,
    tag: tag,
  }

  return (
    <div className={`nc-WorkshopServicesPage ${className}`} data-nc-id="WorkshopServices">
      <Helmet>
        <title>Kocars - Venta de Vehiculos</title>
      </Helmet>

      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 3
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">
          {index === 1 ? <WorkshopListing1 CarWorkShopForm={X} setFullName={setFullName} setphone={setphone} setmark={setmark} setmodel={setmodel} setyear={setyear} setkm={setkm} settag={settag} /> :
            index === 2 ? <WorkshopListing2 CarWorkShopForm={X} setdescription={setdescription} /> :
              <WorkshopListing3 CarWorkShopForm={X} setIndex={setIndex} />}
        </div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          {index > 1 && <ButtonSecondary onClick={() => changeIndexRest()}>Atrás</ButtonSecondary>}
          {index < 3 && <ButtonPrimary onClick={() => changeIndex()}>
            {"Continuar"}
          </ButtonPrimary>}
          {index === 3 && <ButtonPrimary href="/info" onClick={() => InsertCar(X)}>
            {"Finalizar"}
          </ButtonPrimary >}
        </div>
      </div>
    </div>
  );
};

export default WorkshopServicesPage;

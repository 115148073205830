import React, { FC, useState, useEffect } from "react";
import StartRating from "components/StartRating/StartRating";
import SectionCarousel from "components/SectionCarousel/SectionCarousel";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import { getDetail } from "data/listings";
import { CarDataType, CarouselType } from "data/types";
import { Link } from "react-router-dom";
var numeral = require("numeral");

export interface ListingCarDetailPageProps {
  className?: string;
}

const includes_demo = [
  { name: "Proveer Descripción para este Item" },
  { name: "Proveer Descripción para este Item" },
  { name: "Proveer Descripción para este Item" },
  { name: "Proveer Descripción para este Item" },
  { name: "Proveer Descripción para este Item" },
  { name: "Proveer Descripción para este Item" },
];

const carousel: CarouselType[] = [
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FImages%2FCSR1.png?alt=media&token=f3a68238-faa8-40ad-8ce2-c5faf3ce1599",
    title: "Certificamos todos nuestros autos",
    desc: "Todos nuestros vehículos son certificados para asegurar que estén en óptimas condiciones.",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FImages%2FCSR2.png?alt=media&token=a15a2ce4-7eb1-4005-9a14-652441e160df",
    title: "Servicio Post-Venta",
    desc: "Contamos con instalaciones de un taller en El Rosal que le permite cuidar de su inversión a través de nuestro equipo. ",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FImages%2FCSR3.png?alt=media&token=ffc60bd1-4dc1-4238-9457-650892577ffb",
    title: "Confianza",
    desc: "Conocemos la procedencia de su vehículo y le acompañamos durante todo el proceso.",
  },
];

const ListingCarDetailPage: FC<ListingCarDetailPageProps> = ({
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap ">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge
            color="pink"
            name={VehicleActual !== undefined ? VehicleActual.gearshift : ""}
          />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          <Avatar
            sizeClass="h-10 w-10"
            radius="rounded-full"
            imgUrl={VehicleActual !== undefined ? VehicleActual.logo : ""}
          />
          <span> · </span>
          {VehicleActual !== undefined ? VehicleActual.title : ""}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <span className="ml-1">
            {" "}
            Año: {VehicleActual !== undefined ? VehicleActual.year : ""}
          </span>
          <span>·</span>
          <span>
            <span className="ml-1">
              {" "}
              Km:{" "}
              {VehicleActual !== undefined
                ? numeral(VehicleActual.km).format("0,0")
                : ""}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderSectionTienIch = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Parámetros del Vehículo </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
          {/* TIEN ICH 1 */}
          {Amenities_demos.map((item, index) => (
            <div key={index} className="flex items-center space-x-4 ">
              <div className="w-10 flex-shrink-0">
                <img src={item.icon} alt="" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="text-center font-semibold">{item.label}</span>
                <span>{item.name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <>
        <div className="listingSection__wrap">
          <h2 className="text-2xl font-semibold">Descripción</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="text-neutral-6000 dark:text-neutral-300">
            <p>{VehicleActual?.description}</p>

            <div className="mt-6 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <div className="mt-6 text-neutral-6000 dark:text-neutral-300">
              {VehicleActual?.feature
                .filter((_, i) => i < 12)
                .map((item, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    <i className="las la-circle text-2xl"></i>
                    <span>{item}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Incluye </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {includes_demo
            .filter((_, i) => i < 12)
            .map((item, index) => (
              <div key={index} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.name}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap blackground">
        <div className="text-center w-full max-w-2xl mx-auto">
          <NcImage
            containerClassName="mb-8 max-w-[200px] mx-auto"
            src="https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FLogo%2Foriginal.png?alt=media&token=36bc50b7-a310-461a-a97e-c41310842be4"
          />
          <h2 className="mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50 text-2xl font-semibold">
            Certificado Kocars
          </h2>
          <span className="block text-neutral-6000 dark:text-neutral-300">
            En Kocars queremos cuidarte y que tu vehículo semi-nuevo esté en las
            mejores condiciones, y gracias a nuestro taller mecánico, eso es
            posible.
          </span>
          <br></br>
          <span>
            Es por esto que a través de una minuciosa inspección, creamos el
            Certificado Kocars para garantizar seguridad y bienestar en tu
            compra.{" "}
          </span>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          <div>
            <div className="text-center font-semibold">Revisión</div>
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span> Llantas y frenos con mas de media vida util</span>
              </div>
            </div>
            <br />
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>
                  Motor, transmisión, suspensión, y tren motríz certificado
                </span>
              </div>
            </div>
            <br />
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>Libre de adeudos y multas</span>
              </div>
            </div>
            <br />
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>Interiores y exteriores dentro del estándar Kocars </span>
              </div>
            </div>
          </div>
          <div>
            <div className="text-center font-semibold">
              Cumplimiento legal garantizado
            </div>
            <br />
            <br />
            <div className="flex items-center space-x-3">
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src="https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FIcons%2Fbook.png?alt=media&token=591d2415-b13c-4997-881c-885b80321b23"
              />
              <span>
                Como todos los autos KoCars, este auto no tiene reportes de robo
                ni ha estado involucrado en accidentes.
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSetionCarousel = () => {
    return (
      <div>
        <SectionCarousel CarouselType={carousel} />
      </div>
    );
  };
 
  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">A Considerar</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Política de Reserva</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            La reserva es pago inicial para enganchar el vehículo y asegurarlo
            mientras se gestiona el proceso de compra. Puede ser un porcentaje o
            la entrega de un vehículo como parte de pago.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Prueba de Manejo</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            La prueba de manejo es una experiencia que puedes solicitar
            directamente en nuestro concesionario, con el objetivo de probar el
            desempeño del vehículo en la vía y bajo condiciones reales.
          </span>
        </div>
      </div>
    );
  };

  const renderSidebarPrice = () => {
    const linking: string = "/detalle-contacto/" + VehicleActual?.id;
    const branch: string | undefined = VehicleActual?.branch_name;
    console.log("data", VehicleActual);

    return (
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-2xl font-semibold">
            Precio a Consultar
            {/* {VehicleActual !== undefined ?
              numeral(VehicleActual.price)
                .format('$0,0.00') : ""} */}
          </span>
          <StartRating />
        </div>

        {/* SUM */}
        <div className="flex flex-col space-y-4 ">
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Sucursal</span>
            <span>{branch}</span>
          </div>
          <div className="flex justify-between">
            <span>9:00 a.m. - 5:00 p.m.</span>
          </div>
          <div className="flex justify-between">
            <span>Contacto: (+58) 424-131.83.35</span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary href={linking}>Me Interesa</ButtonPrimary>
      </div>
    );
  };

  // USE STATE PHOTOS
  const [PHOTOS, setphotosvehicle] = useState<string[]>([]);
  // const [VehicleActual, setdatavehicle] = useState<CarDataType | undefined>(
  //   undefined
  // );
  const [VehicleActual, setdatavehicle] = useState<CarDataType | undefined>(
    undefined
  );

  // CONSULT FIREBASE
  // function ObtainPhotos(queryString: string) {
  //   getDetail(queryString).then((g) => {
  //     g.forEach((data) => {
  //       console.log("Test1: ", g);
  //       console.log("Test2: ", data);
  //       setphotosvehicle(data.galleryImgs);
  //       setdatavehicle(data);
  //     });
  //   });
  // }

  useEffect(() => {
    let querystring = window.location.pathname.split("/", 3)[2];
    getDetail(querystring).then((g) => {
      g.forEach((data) => {
        console.log("Test1: ", g);
        console.log("Test2: ", data);
        setphotosvehicle(data.galleryImgs);
        setdatavehicle(data);
      });
    });
    // ObtainPhotos(querystring);
  }, []);

  const Amenities_demos = [
    {
      label: "Color",
      name: VehicleActual?.color,
      icon: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FIcons%2FInteriorIcon.svg?alt=media&token=3be3763c-611f-4793-9cc6-5e67e9083272",
    },
    {
      label: "Historial de Servicios",
      name: "Actualizado",
      icon: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FIcons%2FServiceHistoryIcon.svg?alt=media&token=5b228365-161e-419c-a442-29ed7179689d",
    },
    {
      label: "Motor",
      name: VehicleActual?.engine,
      icon: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FIcons%2FEngineIcon.svg?alt=media&token=0f6145ab-9d4b-4412-a4b1-34bcedaa0749",
    },
    {
      label: "Transmisión",
      name: VehicleActual?.transmission,
      icon: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FIcons%2FTransmission%20Icon.svg?alt=media&token=cdf03363-5da1-4739-9840-38f9f09d9ba4",
    },
    //{ label: 'Fuerza', name: VehicleActual?.hp + " Hp", icon: 'https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FIcons%2FDriveTypeIcon.svg?alt=media&token=46f702d2-8679-4280-b641-c12f567305c4' },
    {
      label: "Carroceria",
      name: VehicleActual?.body,
      icon: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FIcons%2FBodyStyleIcon.svg?alt=media&token=75409fce-de7a-4c07-a4fb-5dfb0b34038a",
    },
    //{ label: 'Asientos', name: '5', icon: 'https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FIcons%2FSeatIcon.svg?alt=media&token=6c31a86c-02a0-4ce4-a61e-df2a878ebce1' },
    {
      label: "Combustible",
      name: VehicleActual?.engine_type,
      icon: "https://firebasestorage.googleapis.com/v0/b/kocars-desarrollo.appspot.com/o/Iconos%2FIcons%2FMPGIcon.svg?alt=media&token=a1ae59b8-912d-4e9c-ad22-66078573ba6b",
    },
  ];

  const linking: string = "/detalle-contacto/" + VehicleActual?.id;
  return (
    <div
      className={`nc-ListingCarDetailPage  ${className}`}
      data-nc-id="ListingCarDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS !== undefined ? PHOTOS[0] : "#"}
                prevImageHorizontal
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            <div
              className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(1)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS !== undefined ? PHOTOS[1] : "#"}
                prevImageHorizontal
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            {PHOTOS !== undefined
              ? PHOTOS.filter((_, i) => i >= 2 && i < 4).map((item, index) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 2 ? "block" : ""
                    }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={item || ""}
                      prevImageHorizontal
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 2)}
                    />
                  </div>
                ))
              : ""}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Ver todas las fotos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS !== undefined ? PHOTOS : []}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
      </>

      {/* MAIn */}
      <main className="container mt-11 flex ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          {renderSectionTienIch()}
          {renderSection2()}
          {/* {renderSection3()} */}
          {renderSection4()}
          {renderSetionCarousel()}
          {renderSection8()}
        </div>
        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow">
          <div className="sticky top-24">{renderSidebarPrice()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
        <div className="container flex items-center justify-between">
          <span className="text-2xl font-semibold">
            <span>
              {VehicleActual !== undefined ? VehicleActual.price : ""}
            </span>
          </span>

          {/* SUBMIT */}
          <ButtonPrimary>
            <Link to={linking}>Contacto</Link>
          </ButtonPrimary>
        </div>
      </div>

      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">{/* SECTION */}</div>
    </div>
  );
};

export default ListingCarDetailPage;

import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 DIRECCIÓN",
    desc: "	Calle Carabobo con Esquina Sojo, Concesionario Kocars, El Rosal, Caracas 1060, Miranda",
  },
  {
    title: "💌 CORREO",
    desc: "kocarsve@gmail.com",
  },
  {
    title: "☎ TELÉFONO",
    desc: "(+58) 424 131-83-35",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
      <title>Kocars - Venta de Vehiculos</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contáctanos
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 REDES SOCIALES
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" action="#" method="post">
                <label className="block">
                  <Label>Nombre Completo</Label>

                  <Input
                    placeholder="Nombre Completo"
                    type="text"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Correo Electrónico</Label>

                  <Input
                    type="email"
                    placeholder="Correo Electrónico"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Teléfono</Label>

                  <Input
                    type="number"
                    placeholder="Teléfono"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Mensaje</Label>

                  <Textarea className="mt-1" rows={6} />
                </label>
                <div>
                  <ButtonPrimary type="submit">ENVIAR MENSAJE</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>
      </div>
    </div>
  );
};

export default PageContact;

import React, { FC, ReactNode, useState, useEffect } from "react";
import { VEHICLES_LINSTINGS } from "data/listings";
import {  CarDataType } from "data/types";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";


export interface SectionGridFeaturePlacesProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  gridClass = "",
  heading = "Vitrina de Vehículos",
  subHeading = "Venta, compra y consignación de vehículos en un solo lugar.",
  headingIsCenter,
  tabs = [],
}) => {
  const renderCard = (stay: CarDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };
  const [DATA, setdata] = useState<CarDataType[] | undefined>(undefined)

  const Changer = async () => {
    const X: CarDataType[] = await VEHICLES_LINSTINGS;
    const DEMO_DATA: CarDataType[] = X.filter((_, i) => i < 100)
    setdata(DEMO_DATA)
  }
  useEffect(() => {
    Changer()

  }, []);

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"Kocars"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => { }}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >

        {DATA === undefined ? '' : DATA.map((stay) => renderCard(stay))}
      </div>
      <div className="flex mt-5 justify-center items-center">
    
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;

//   <ButtonPrimary href="/lista-vehiculos">Show me more</ButtonPrimary>
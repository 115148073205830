import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Header from "shared/Header/Header";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import PageContact from "containers/PageContact/PageContact";
import PayPage from "containers/PayPage/PayPage";
import AlliancePage from "containers/AlliancePage";
import PageInfo from "containers/PageInfo/PageInfo";
import WorkshopServicesPage from "containers/Workshop&ServicesPage/Workshop&ServicesPage";
//
import CommonLayout from "containers/PageAddListing1/CommonLayout";
import PageVitrina from "containers/PageVitrina/PageVitrina";


export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/lista-vehiculos", component: ListingStayPage },
  //
  { path: "/detalle-vehiculo", component: ListingCarDetailPage },
  //
  { path: "/detalle-contacto", component: PayPage },
  //
  { path: "/contacto", component: PageContact },
  //
  { path: "/servicios-taller", component: WorkshopServicesPage },
  //
  { path: "/publica-tu-vehiculo", component: CommonLayout },
  //
  { path: "/alianzas", component: AlliancePage },
  //
  { path: "/info", component: PageInfo },
  //
  { path: "/vitrina-de-vehiculos", component: PageVitrina },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
